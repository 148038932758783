import PropTypes from 'prop-types';

// Styles
import styles from './TwoColumnsBlock.module.css';

function TwoColumnsBlock({ children }) {
  return (
    <section className={styles.layout}>
      {children}
    </section>
  );
}

TwoColumnsBlock.propTypes = {
  children: PropTypes.node,
};

TwoColumnsBlock.defaultProps = {
  children: null,
};

export default TwoColumnsBlock;
